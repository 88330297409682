@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header {@include sec-default(); text-align: center;//position:absolute;
  .headWrap{width:100%; float:none; display: block;position:relative;z-index:1;}
  ///////////////

  .navMm{@include sec-default(); float:none; padding: 5px 20px 8px; background-color: transparent;}

  .logoBlk, .navBarCustom, .navItem, .Weather{display: inline-block;}
  .logoBlk{width:330px;float:left; padding:0; margin:2px 0 0; min-height:auto;}
  .navBarCustom{float:none;margin:45px auto 0;
    a:hover, li:hover a, button:hover, li:hover button {color: lighten($color2, 25%) !important; opacity: 1;}
  }
  .Weather{width:230px; float:right; margin-top:-5px;
    .weatherWidge, .fmReport{margin:0;}
  }
  .navItem {margin:0 15px;
    a, button{font-weight: 500; color: $color2; text-transform: none; font-size:1.1rem;}
    button:focus{border:none; outline:none;}
  }
  
  .custDrop{padding:5px 0; margin-top:0; text-align: left;
    .dropLi{@include sec-default(); margin: 5px 0;
      a{padding: 0px 0 0px 20px; color: $color1; font-size: 1em;}
    }
  }

  .mobNav{display: none; float:none; vertical-align: top; margin-top:35px;}
  .mobileNavBtn{@include sec-default();width:22px;
    span{@include sec-default(); height:2px; margin:2px 0; background-color: $color2;}
  }

  @media (min-width:1448px){
    .navMm{padding:5px 0 8px;}
    .Weather, .logoBlk{position:absolute;z-index: 1;}
    .Weather{right:0;}
    .logoBlk {left:0;}
    .navBarCustom{margin-bottom:35px;}
  }

  @include bp(tw) {
    .navItem {
      a, button{font-size:1.05rem;}
    }
  }

  @media (max-width:1080px){padding-bottom:7px;
    .headWrap{width:80%;}
    .navMm{padding:5px 0 0;}
    .medDef{width:600px;}
    .Weather{display: none;}
    .navBarCustom{float:right;}
  }

  @include bp(tb) {padding-bottom:0;
    // .headWrap{width:90%;}
  // }
  // @media (max-width:959px) {
    .headWrap{width:580px;}
    .navBarCustom{display: none;}
    .mobNav{display: inline-block;}

    .logoBlk{width:60%; float:none;
      img{max-width:300px;@include center-block();}
    }
  }

  @include bp(oc) {
    .headWrap{width:90%;}
    .medDef{width:85%;}

    .logoBlk img{max-width:250px;}
    .mobNav {margin-top: 25px;}
  }
  
  @media (max-width:440px) {
    .navMm{padding-top:4px;}
    .logoBlk{width:auto;}
  }

  @include bp(xs){
    .ctaBanner{
      .insideAlt{width:85%;}
      .hlf1{width:50%;}
    }
  }

  @include bp(xx) {
    .headWrap{width:95%;}
    .logoBlk img{max-width:220px;}
    .mobNav {margin-top: 25px;}
  }
}

.SideNavList{padding:40px 10px 0 10px; width:300px;
  .SideNav{margin-top:10%;}
  .closeIcon {color: $color2; position: absolute; right:10px; top:15px; cursor: pointer; font-weight: normal;
    svg{width:30px; height:30px;}
  }

  .navItem {@include sec-default(); margin:0; padding: 15px 15px;
    &.niBtn{background-color: rgba($color2, .4); padding: 10px 15px; margin: 5px 0;}
    a, button{font-weight: 600; color: $color1; text-transform: none; font-size:19px;
      &:hover{color:$color2; opacity: 1;}
    }
    button{padding:0;}
  }
}

.ctaBanner{@include sec-default(); background-color: $color1; padding: 2px 0; color: $white; text-align:center;
  p,a {color: $white; font-size: 1.3rem; line-height: 1em;}
  p.tpd{font-size:1.1rem;}
  a{font-weight: bold; font-size:inherit;}
  br{display: none;}
  
  @include bp(mb){
    p,a{line-height: 1.5em; margin-bottom:3px; font-size:1.15rem;}
    br{display: block;}
  }

  @include bp(sm){
    .insideAlt{width:90%;}
    p,a{font-size:17px;}
  }
}