@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.LocationDisclaimer{font-weight:500; color:darken($yellow, 20%);
  a{color:darken($yellow, 30%); font-weight:bold;}
  span.und{text-decoration: underline; font-weight: bold;}
  
  .subLd{display: none;}
  &.footer,&.rentals{
    .subLd{display:inline;}
  }

  &.footer a{color: $color1;}

  &.contactLd, &.homeLd{color:darken($color1, 5%);
    a{color:$color2;}
  }
  
  &.faqLi{color:$color2;
    a{color:darken($color1, 5%);}
  }
}