@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Rentals {@include sec-default();
  .introBlock{@include sec-default();margin:0px; position: relative; z-index: 0;
    &::before{@include after(); width:44%; right:11%;
      @include sec-bgimage('./images/summer-header.png'); background-position: center right;
    }
    .tbox{float:left; width:45%; margin:90px 0 100px -5%;}
    .textLink{font-weight:bold;}
    .textLink.red{color:$red;}

    h1{@include sec-default(); margin:0px 0 7px; color:darken($yellow,17%); font-size:2rem;}
    h2{margin:0 0 0px; font-weight:300; font-size:1.4rem;}
    h2 span{font-weight:500;}
    p{font-size:17px;}//color:darken($color1, 7%);}
    p+p{margin-top:0;}
    button{font-size: inherit; padding:0;}
    a, button{font-weight:500;}
    span a{font-weight:bold;color:darken($yellow,25%);}
  }

  @media (min-width:1600px) {.introBlock .tbox{margin-left: -12%;}}
  @media (max-width:1445px) {.introBlock .tbox{margin:70px 0 70px -3%;}}
  @media (max-width:1300px) {
    .introBlock {
      &::before{right:9%;}
      .tbox{margin:70px 0 70px 0;}
    }
  }

  @include bp(tw) {
    .introBlock{
      &::before{right:5%; width:35%;}
      .insideAlt{width:85%;}
      .tbox{width:55%; margin:40px 0 40px 2%;}
      h1{font-size:1.6rem;}
      h2{font-size:1.2rem;}
    }
  }

  @include bp(tb) {
    .introBlock{
      &::before{right:8%;}
      p{margin-top:5px;}
    }
  }

  @include bp(br) {
    .introBlock {
      &::before{display:none;}
      .medDef{width:700px;}
      .tbox{@include sec-default(); margin:20px auto; text-align:center;}
      h1{font-size:1.4rem;}
      h2{font-size:1.1rem;}
    }
  }

  @include bp(md) {
    .introBlock{
      .medDef{width:90%;}
      p{font-size:16px;}
    }
  }

  @include bp(mb) {
    .introBlock {
      .insideAlt{width:90%;}
      h1{font-size:1.27rem;}
      h2{font-size:1.05rem;}
      p{margin-bottom:0px;}
    }
  }
}