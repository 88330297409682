@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Asap:wght@700&display=swap');
// &family=Acme
@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

// ::selection{background: boat;}
// ::-moz-selection {background: boat;}
*, *::before, *::after {box-sizing: border-box;}
body{margin:0;}
html {line-height: 1.5em; font-size: 17px; color:$color2;font-family:$body;
  h1, h2, h3, h4, h5, p, li, a, span {color: $color2; line-height: 1.4em; margin: 8px 0;
    font-family:$body; font-weight:400;
    &.bmargin0 {margin-bottom: 0;}
    &.bmargin5 {margin-bottom: 5px;}
  }
  p,li{font-weight: 400;}
  p{font-size: 1rem; margin-top:7px;}
  span{color:inherit;font-weight:inherit;font-family:inherit;}
  
  h1{font-size: 2.1rem; line-height: 1.3em; font-weight:bold;}
  h2{font-size:1.5rem;line-height: 1.3em;}
  h3{font-size: 1.4rem;}
  h4{font-size:1.2rem;}

  @include bp(tb) {
    h1{font-size: 1.8rem;}
    h2{font-size:1.3rem;}
    h3{font-size: 1.2rem;}
    h4{font-size:1rem;}
  }

  @include bp(br) {h1{font-size: 1.65rem;}}
  @include bp(md) {h1{font-size: 1.65rem;}}
}

a {text-decoration: none;
  &:hover {opacity: .8; text-decoration: none; @include ease(.3s);}
}
button{background: none; border:none; border-radius:0; color:inherit; font-weight:inherit; font-family: inherit;
  // font-size:inherit; letter-spacing: inherit;
  &:hover{cursor: pointer; color:inherit !important;}
}
img{@include center-block();}
ul {margin:0; list-style-type: none; padding-left: 0;}
li{list-style-type: none;}

.fmbrWrap,.bgWrap{@include sec-default();position:relative;}
.Content{@include sec-default(); 
  @media (min-width:992px) {min-height:55vh;}
  @media (min-width:1024px) {min-height:65vh;}
}

.announce{@include sec-default();background-color:$melon;position:relative;text-align:center;position:relative;z-index:2;
  &.closed{padding:20px 0;}
  &.open{padding:10px 0;}

  p{@include sec-default();font-size:19px;color:$white;margin:0;font-weight:bold;text-shadow: 0px 1px 1px darken($melon,15%);}
  a,span{color:$color1-lt;font-weight:bold;text-decoration:underline;}
  span{text-transform:uppercase;}

  @media (max-width:639px){
    // p br{display:none;}
    p{font-size:18px; width:90%;@include center-block();}
  }
  @media (max-width:500px){p{font-size:17px;}}
}
.excBar{@include sec-default();background-color:$melon;position:relative;text-align:center;position:relative;z-index:2;padding:5px 0;
  p{@include sec-default();color:$white;margin:0;font-weight:bold;text-shadow: 0px 1px 1px darken($melon,15%);}
  a,span{color:$color1-lt;font-weight:bold;}
  a{text-decoration:underline;opacity:.8;}
  span{font-style:italic;}

  @include bp(nn){p{width:90%;max-width:450px;@include center-block();}}
  @media (max-width:500px){p{font-size:16px;}}
}
.excBar+.excBar,
.announce+.excBar{background-color:$ltMelon;
  p{color:$melon;text-shadow:none;}
  a,span{color:$melon;}
}
// .announce+.excBar, .announce+.excBar+.excBar{}
.announce+.excBar+.excBar{padding-top:0;}

.ToTopBtn{left:15px;margin-bottom: 0px;}

.musicBtns{position:fixed;z-index: 99; right:0; top:120px; width:45px;
  background-color: $color1-lt; padding:5px 2px 5px 0; box-shadow: -1px 1px 5px rgba($black,.2);
  button{width:100%; padding:0;}
  button:hover{opacity: .7;}
  .playBtn {margin-bottom:10px;}
  .pauseBtn{color:$red;}

  @include bp(tb) {top:0px;width:auto;padding:5px 2px 5px 0;
    button{width:auto; margin:0 5px;}
    .playBtn {margin-bottom:0px;}
  }

  @include bp(oc) {top:57px;width:35px;padding:0;
    button{width:auto; margin:5px 0;@include sec-default();}
    // .playBtn {margin-bottom:0px;}
  }
  @include bp(xs) {top:169px;}

}