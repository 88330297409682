@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.booqHide{display: none;}
.booqShow{@include sec-default(); position: relative; margin-bottom:60px; text-align: center;
  .insideXl{max-width: 1450px;}
  input{border-color:rgba($color2,.1);}
  .sideBar, .productsGrid{display: inline-block; vertical-align: top;}
  .productsGrid{width:72%;margin-right:-2%;}
  .sideBar{width:27%; max-width:330px; margin-right:2%;
    text-align:left; padding:20px 15px; border-radius:3px;
    background-color:#f3fafa; box-shadow: 10px 10px darken($color1-lt,6%);

    label, select, h3, .delivTitle, .sideBarIn li div, p{color:$color2; font-family:$body !important; font-size:17px !important;}
    p b, h3, .delivTitle, label{color:darken($color1, 7%);}
    label, select, h3{text-transform: capitalize;}
    .sideBarIn li div {line-height:1.2em !important; margin-top:15px;}
    p.bold, p b, .delivTitle, h3 b{font-weight: 600;}

    p.instr{margin-bottom:0; text-align: center;}
    p.top2{margin-top:0px; text-align: center;
      a{font-weight: bold; color:inherit}
    }
    p.pkt{color: darken($yellow, 20%); width:95%; @include center-block();}
    p.faq{font-style: italic; font-weight:500; text-align: center;
      button{padding:0; font-size:inherit; font-style: inherit;}
    }

    // .sideDelivery.dsm{display: none;}
    .delivAcc li, .delivContent{margin:0;}
    .delivTitle{padding:0;}
    .delivTitle::before{width:1.2em; height:1.2em; background-position: center center;}
  }
  .sideBarIn{border:0;padding:0;background-color:transparent;
    form{position:relative;width:100%; padding-bottom:130px;}//padding-bottom:70px;
    form::after{@include after();left:0;
      // height:60px;content:"*Be sure to enter the correct checkout date.";
      height:100px;content:"*BE SURE TO ENTER THE CORRECT CHECKOUT DATE. Our team will pick everything up the morning of your checkout.";
      font-size:15px;line-height:1.5em;color:$red;font-weight:bold;
    }
  }

  .coTextRow{@include sec-default();position:absolute;top:0;
    p, p b{color:$red;}
  }
  p strong{color:$red;
    span{font-style: italic;}
  }
  
  @media (max-width:1300px){
    .sideBar{width:30%; max-width:100%;}
    .productsGrid{width:68%;}
  }
  
  @media (min-width:1024px){.sideBar{top:10px; position:-webkit-sticky; position:sticky;}}

  @include bp(br){
    .inside, .insideXl{width:92%;}
    // .sideBar{width:31%; margin-right:0;}
    .productsGrid{width:68%;margin:0; float:right;}
    p.instr, label, select, h3, .sideBarIn li div, p, .delivTitle{font-size:16px !important;}

    .sideBar{width:650px; @include center-block();box-shadow: 6px 6px darken($color1-lt,6%);margin-bottom:30px;padding:15px 30px;
      p.instr{display: none;}
      p.top2{text-align: center;}
      p.pkt{width:100%;}

      // .sideDelivery.dsm{display: block;}
      // .sideDelivery.dlg{display: none;}
    }
    .productsGrid{@include sec-default();}

    .sideBarIn{
      // form{padding-bottom:35px;}
      // form::after{height:30px;z-index:1;}
      form{padding-bottom:75px;}
      form::after{height:55px;z-index:1;}
    }
  }

  @include bp(og) {
    .sideBar{width:90%;}
  }

  @include bp(oc) {
    .inside, .insideXl{width:90%;}
    .sideBar{width:98%;padding:15px 30px;}
  }

  @media (max-width:530px){
    .sideBarIn{
      form{padding-bottom:100px;}
      form::after{height:75px;}
    }
  }

  @media (max-width:410px){
    .sideBarIn{
      form{padding-bottom:125px;}
      form::after{height:95px;}
    }
  }
}