@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.faqsPage {@include sec-default();padding:3% 0 7%;position:relative;z-index:0; 
  &::before{@include before();height:122%;top:-22%;@include sec-bgimage('./images/holidayFaq.png');background-size:cover;background-position: top left;}

  .med2Def{width:99%;max-width:800px;}
  .faqBody{@include sec-default();}
  .fullbox{@include sec-default(); background-color:$fa;box-shadow: 0px 8px 30px rgba($color2,.2); position:relative; z-index:0; padding-top:20px;}

  .titlebox{margin-bottom:25px;}
  h1{font-size:2.5rem; text-transform: uppercase; letter-spacing: 5px;
    margin-left:-2.5%;color:$color2;
  }

  .faqList{width:90%;@include center-block();}
  li{margin-bottom: 20px; position: relative; z-index: 0; padding-left: 10px; 
    &:not(.attnLi)::after{@include before(); width:10px; height:10px; left:-10px; top:6px; @include sec-bgimage('./images/fmbr-disc.png');}
    
    &.attnLi{padding:15px 0;}
    &.attnLi::before, &.Xpickup::before{@include before();width:102%;left:-20px;
      background-color:rgba($red,1);
    }
  }
  button{color: $color1; font-size:19px;}
  li.pickup{
    button.faqQuestion, button.faqQuestion:hover{color:$red;}
    button.faqQuestion:focus, button.faqQuestion:hover{color: $red !important;}
  }

  p, li, svg {color:$color2;}
  .ldText.ldTop{margin-bottom:25px;}
  .ldText.ldTop a:hover{color:inherit;}
  span.und{text-decoration: underline;}

  .faqQuestion{font-size:18px; padding:0; color:$color1; font-weight:500; text-align: left;
    &:not(.attn):focus,&:not(.attn):hover{color:$color1 !important;}
    &:hover{opacity: .75;}

    &::before{width: 1.2em;height: 1.2em;background-position: center center;
      background-image:url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
    }

    &.attn{color:darken($color1, 20%); font-weight: bold;}
  }
  .faqContent{margin-top:0;
    a{font-weight: bold;}
  }

  .notice{@include sec-default();margin-top:15px;background-color:$purple;text-align:center;
    p{color:$white;}
  }
  
  @include bp(br) {
    h1{font-size:2.1rem;}
  }

  @media screen and (max-width:900px){
    h1{font-size:1.85rem;}
    .med2Def{width:90%; max-width:700px;}
    .titlebox{margin-bottom: 15px;}
  }

  @include bp(md){padding:0 0 50px;}

  @include bp(og){padding-bottom:30px;
    .med2Def{max-width:650px;}
    h1{font-size:1.7rem; margin-left:-15px;}

    .notice{margin-top:5px;}
  }

  @include bp(oc){
    .fullbox{padding-top:10px;}
    h1{font-size:1.5rem;}
  }

  @include bp(ph){
    h1{font-size:1.3rem;letter-spacing: 3px;margin-left:0; text-align:center;}
  }
}