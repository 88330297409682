@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer {@include sec-default();
  .hcTop{@include sec-default(); text-align:center; background-color: $color1; padding: 0px 0; 
    p,a{color:$white; font-size:1.3rem; line-height: 1em;}
    a{font-weight: bold;}
  }

  .heroCard{@include sec-default(); text-align:center; padding:20px 0 20px;
    @include sec-bgimage('./images/aa-dunes.jpg'); background-size: cover; background-position:center center;
    position: relative; z-index: 0; &::before{@include before(); background-color: rgba($white, .93);}

    .contentBox{@include sec-default(); position: relative;}
    .image,.text{display: inline-block; vertical-align: top;}
    .image{width:165px;margin-top:10px;}
    .text {width:65%; text-align:left; margin-left:3%;}// margin-top:10px;}

    p.ftTitle{color: $color1; font-size: 2rem; line-height:.85em; text-shadow: 0px 1px 2px rgba($black,.1); margin:0 0 20px; text-transform:uppercase; font-family: $asap;
      span{color: $color2;}
    }
    p{font-size:1.05rem; color:$color2;}
    p.ldText{font-size:16px;}
  }

  .copyHs{@include sec-default();
    p{display: inline-block; font-size: 14px; margin:10px 0 5px;}
    p.copy{float:left;}
    p.hs{float: right; text-align: right;}  
  }

  @include bp(tb) {
    .hcTop{p,a{font-size:1.1rem;}}
    .heroCard{
      p.ftTitle{font-size:1.7rem;}
      p{font-size:1rem;}
    }
  }

  @include bp(tn) {
    .hcTop{p,a{font-size:1.05rem;}}
    .heroCard{padding:20px 0 20px;
      .text{margin-top:0px;}
      p.ftTitle{font-size:1.6rem; margin-bottom: 10px;
        br{display: none;}
      }
    }
  }

  @include bp(oc) {
    .heroCard{padding:15px 0 10px; text-align:center;
      .insideNrw{width:92%;}
      .image, .text{@include sec-default();}
      img{width:125px; @include center-block();}
      .text{margin-top:10px; margin-left: 0; text-align: center;}
      p.ftTitle{font-size:1.3rem; margin-bottom:5px;}
    }
  }

  @include bp(mb) {
    .copyHs {
      p, p.copy, p.hs{@include sec-default(); text-align: center; margin:5px 0 0;}
    }
  }
}