$fa8: #fafaf8;
$fa: #fafafa;
$fa2: darken($fa, 1%);
$df: #dfdfdf;
$sand: #fcf8e3;
$white: #fff;
$black: #000;
$red: #bb2830;
$melon:#f85260;
$purple:#6656c3;
$ltMelon:#fccbcf;


$color1: #4ab1be;
$color2: #172d53;
$yellow: #fec756;
$color1-md:#a4d8de;
$color1-lt:#ecf7f8;

$text-lt:#e7e9ee;
$border: 1px solid rgba($black,.1);

$hind: 'Hind Siliguri', sans-serif;
// $acme: 'Acme', sans-serif;
$asap: 'Asap', sans-serif;
$body: $hind;

$semi: 500;
$light: 300;