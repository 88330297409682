@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

// box-shadow: 0px 10px 30px rgba($black, .3);
// box-shadow: 2px 0px 20px rgba($black,.3);
@include bp(ph){.insideN.heroWrap{@include sec-default();}}

.Home {@include sec-default();
  .fmbHero{@include sec-default();position: relative;// margin-top:120px; 
    min-height:700px; @include sec-bgimage('./images/beach-scene.jpg');background-size:cover; background-position: center right; 
    .titleBox{position: absolute; width:430px;right:2%;}

    h1{color: $color2; font-size: 3.1rem; font-weight:bold; text-transform: uppercase; font-family: $asap; line-height:1em;margin-bottom:20px;
      margin-bottom:0;//
      text-shadow: .5px 1px lighten($color2, 20%);
      b{color: $white;}
    }
    h2{font-size:1.35rem; color:$white; font-weight: 400;text-shadow: .5px 1px darken($df, 30%);}

    h3{font-weight:500; color:$yellow; margin:0 0 40px; font-size:1.35rem;
      text-shadow: .5px 1px darken($yellow, 30%); letter-spacing: .5px;
      a{color:inherit; font-weight:inherit; font-size: inherit;}
      br{display: none;}
    }

    p{color:$yellow;font-weight:bold; text-transform: uppercase; letter-spacing: 5px; font-size:22px;margin-bottom:20px;text-shadow: .5px 1px rgba($black,.2);}
    
    .tbCTA{@include sec-default(); margin-top: 15px;}
    .fmLinkAlt{font-size:19px; background-color:$yellow; color:$color2;
      text-transform: uppercase; letter-spacing: 2px; padding:12px 30px 11px;text-shadow:none;
      box-shadow:0px 10px 20px rgba($black,.2);
    }

    @include bp(tw){
      h1{font-size: 3rem;}
      h2{font-size:1.25rem;}
    }
    
    @include bp(xl){.titleBox{top:20%;}}

    @include bp(tb){min-height:550px;
      .titleBox{width:42%;right:0; bottom: 70px;padding-right:15px; 
        width:38%;bottom:50px;
      }
      h1{font-size: 2.5rem;}//margin-bottom:15px;}
      p{margin-bottom:15px;letter-spacing:3px;}
      h2{font-size:20px; text-shadow: none;}
      .tbCTA{margin-top: 5px;}
      h3{font-size:20px;letter-spacing: 0; margin-bottom: 30px; font-weight: 600;}
      .fmLinkAlt{font-size:18px;padding:9px 30px 8px;}
    }

    @include bp(br){min-height:500px; 
      .titleBox{width:38%; bottom:12%;}
      h1{font-size: 2rem;}
      p{letter-spacing:0px;font-size:20px;}

      h2, h3{font-size:19px;}
      h3{text-shadow: none;}
      .fmLinkAlt{font-size:17px;padding:5px 20px;}
    }

    // @include bp(tn){margin-top:110px;}

    @media (max-width:775px){min-height:100%; padding:50px 0 60px; background-position: center center; 
      z-index:0; &::before{@include before(); background-color:rgba($color2,.75);}
      .titleBox{position:relative; width:75%; @include center-block(); bottom:auto; text-align: center;padding-right: 0px;}
      h1{color:$color1; text-shadow:.5px 1px $color2;}
      p{font-size:19px;}
      .tbCTA{margin-top: 0px;}
      h3{margin-bottom: 20px;}
    }

    // @include bp(oc){margin-top:90px;}

    @include bp(ph){padding:30px 0 50px;
      .titleBox{width:95%;}
    }

    @include bp(xs){padding:20px 0 40px;
      h1{font-size:1.7rem;margin-bottom:10px; line-height: 1.2em;}
      h2, h3{font-size:18px;}
      h3{text-shadow: none;}
      .fmLinkAlt{font-size:15px;}
    }
    
    // @include bp(xx){margin-top:85px;}
  }

  .promoBanner{@include sec-default(); text-align: center; background-color:$color2; padding:15px 0;
    // br{display: none;}
    p,a{font-family: $asap; text-transform: uppercase; letter-spacing: 1px;}
    p{color:$white; margin:0; font-size:20px; margin: 0; line-height: 1.6em;}
    a{color:$yellow;}

    @include bp(br){
      br{display: none;}
      p{font-size:19px; width:90%; @include center-block();}
    }

    @include bp(oc){
      p{font-size:18px; width:95%;}
    }

    // @include bp(og){}
  }
  
  .fmbhProducts{@include sec-default();padding:50px 0 100px; position: relative; z-index: 1;
    .top{@include sec-default();text-align: center; margin-bottom:60px;
      p{font-size: 22px; margin-top:0; color:darken($color1, 5%);}
      h3{font-size:2.5rem; line-height: 1.1em; text-transform: uppercase; font-weight: 700; color: $color2; font-family: $asap; margin-bottom: 0px;
        span{color:$color1;}
      }
      p.ldText{font-size:19px; width:60%; @include center-block();}
    }
    .hlf1, .hlf2{display: inline-block;}
    .hlf1{float: left; width:55%; margin-left:-5%;}
    .hlf2{float: right; width:52%; margin-right:-7%;}
    
    .row{margin-bottom:25px;
      position: relative; z-index: 0;
      &::before{@include before(); height:15px; width:15px; left:-20px; top:6px; @include sec-bgimage('./images/fmbr-disc.png');}

      h4{font-weight:600; margin:0 0 3px; color:$color1; font-size:1.35rem;}
      p{font-size: 19px; margin-top:0;}
      p.bold, p b{font-weight: 600;}
      .timepd{color:darken($yellow,17%);}
    }
    .fmLink, .fmLinkAlt, .fmLinkY{letter-spacing: 1px; text-transform: uppercase; text-shadow:none; font-size:16px;box-shadow:0px 10px 20px rgba($black,.1);}
    .fmLinkY{margin-left:15px; font-size:17px;//padding:13px 20px;
      &:hover{background-color:$yellow; border:none; border-radius:0; cursor: pointer; color:inherit;}
    }

    @include bp(tw) {
      .hlf1{width:50%; margin-left:0;}
      .hlf2{width:50%; margin-right:0;}
    }

    @include bp(tb) {padding:20px 0 60px;
      .top{margin-bottom: 20px;
        h3{font-size:2rem;}
        p{font-size:20px;}
      }
      .hlf1{width:55%; margin-left:2%;}
      .hlf2{width:40%; margin-right:2%; margin-top:15px;}
      .spacer3{height:5px;}
    }

    @include bp(br) {padding:10px 0 40px;
      @include sec-bgimage('./images/road-trip.png'); background-size:22%; background-position: 94% 105%;

      .top{margin-bottom: 20px;
        h3{font-size:1.7rem;}
        p{font-size:18px; margin-bottom: 2px;}
        p.ldText{font-size:16px; width:65%;}
      }
      .hlf1{width:75%; margin-left:0;}
      .hlf2{display:none;}

      .row{margin-bottom:15px;
        &::before{height:12px; width:12px; top:8px;}
        h4{font-size:20px; margin-bottom:0;}
        p{font-size: 18px;}
      }
      .spacer3{height:15px;}
      .fmLink, .fmLinkAlt{font-size:15px;}
    }

    @include bp(md) {
      background-size:20%; background-position: 97% 100%;
      // background-size:27%; background-position: 97% 100%;

      .top{margin-bottom: 20px;
        p.ldText{width:75%;}
      }
      .hlf1{width:90%;}
    }

    @include bp(og) {background-image:none;
      // background-size:150px; background-position: 97% 100%;
      .top{
        h3{font-size:22px;}
        p.ldText{width:88%;}
      }
      .hlf1{@include center-block(); text-align: center;}

      .row{@include sec-default();margin-bottom:10px;
        &::before{top:6px; display:none;}
        h4{font-size:19px;}
      }
      .spacer3{height:30px;}
    }

    @include bp(oc) {
      // background-size:100px; background-position: 99% 100%;
      .top p.ldText{width:100%;}
    }
    
    @media (max-width:500px) {
      .top {margin-bottom:10px;
        p{width:80%; @include center-block(); margin-bottom:8px;}
      }
      .hlf1{width:98%;}
    }

    // @include bp(sm) {background-image:none;
    //   .hlf1{text-align:center;}
    //   .row::before{display:none;}
    // }

    @media (max-width:400px){padding:10px 0 30px;
      .fmLink, .fmLinkAlt, .fmLinkY{width:100%; float:none; display:block; clear:both; padding:12px 0; margin:0 auto; max-width: 300px;}
      .fmLinkY{margin-top: 15px;}
    }

    @include bp(xx) {background-image:none;
      .top {
        h3{font-size:20px;}
        p{width:95%;}
      }
    }
  }
    
  .booqTop{@include sec-default(); background-color: $white;height:100px; position: relative;
    // .signAbs{position: absolute; width:200px;  left:30px; top:-135px;}
    svg {position: absolute;bottom: 0;width: 100%;height: 10vw;}

    @include bp(br){height:80px;}
    @include bp(oc){height:10px;
      svg{height:7vw}
    }
  }
  .homeCatGrid{@include sec-default();margin-top:-1px; padding:0px 0 70px; position: relative; z-index: 0; text-align: center;
    &::before{@include before(); height:67%;background-image: linear-gradient(lighten($color1,7%), darken($color1, 15%));} 
    
    p.title{color:$white; font-family: $asap; font-size:40px; font-weight:bold; text-transform: uppercase; text-shadow:.5px 1px darken($color1,8%); margin-bottom: 50px;}

    .catItem{display: inline-block; width:30%; margin: 0 1%; background-color:#fafafa;
      @include sec-bgimage('./images/fmbrSymbolColors2.png'); background-size:85%; background-position: top center;
      position: relative; z-index: 0; overflow: hidden;
      &::before, &::after{@include after(); background-color:$yellow;}
      &::before{right:0; height:25px;}
      &::after{height:60px; left:0;transform: skewY(-8deg);}

      &:hover{opacity: 1; 
        p{color:$white; text-shadow: 0px 1px 1px rgba($color2,.4);@include ease(.3s);}
      }

      img{width:80%; @include center-block(); position: relative; z-index:-1; background-color:rgba($fa,.7);} // 
      p{font-size:20px; text-transform: capitalize; font-weight:500; margin:0 0 12px;}
    }

    @include bp(tw){
      .catItem p {font-size:19px;margin:0 0 10px;}
    }

    @include bp(tb){padding-bottom: 50px;
      p.title{font-size:34px; margin-bottom: 30px;}
      
      .catItem::after{height:55px;}
    }

    @include bp(br){
      p.title{font-size:30px;}
      .catItem p{font-size:18px;}
    }

    @include bp(md){padding-bottom: 40px;
      &::before{top:-1px;}
      .medDef{width: 95%;}
      p.title{font-size:25px; margin-bottom: 20px; margin-top:-5px;}
      .catItem{
        &::after{height:50px;}
        p{margin-bottom: 7px;}
      }
    }

    @include bp(og) {
      .catItem p{font-size:17px;}
    }
  
    @include bp(oc) {padding-bottom:25px;
      .medDef{width: 100%;}
      p.title{font-size:22px; margin:0 0 10px;}
      .catItem{width:150px;
        &::after{height:40px;}
        p{font-size:16px; margin-bottom:5px;}
      }
    }

    @media (max-width:480px){
      &::before{height:160px;}
      .medDef{width:90%;}
      p.title{font-size:20px;}
      .catItem{width:48%; margin:0;}
      .catItem.loungers{float:left;}
      .catItem.hobbies{float:right;}
      .catItem.extras{display: none;}
    }

    @include bp(xs){
      &::before{height:140px;}
    }
  
  }

  .fmbhBeach{@include sec-default();padding:40px 0 0; 
    .med2Def{width:1010px;}
    .fmbInner{@include sec-default(); position: relative; z-index: 0;padding-bottom:15px;
      &::before{@include before(); @include sec-bgimage('./images/footprints-sign.png'); background-size:33%; background-position: bottom left;}
    }
    .textHlfTop, .textHlfBtm{width:65%; float:right; display:block;}

    h4{color:$color1;font-weight:600; font-size:20px;margin:5px 0 0px;}
    p, li{font-size:18px; margin-top:0;}
    ul{padding-left:20px; margin-top:15px; @include sec-default();}
    li{margin-bottom:15px; position: relative; padding-left: 8px;
      &::before{@include before(); width:12px; height:12px; left:-12px; top:6px; @include sec-bgimage('./images/fmbr-disc.png');}
    }

    @include bp(tb) {padding:10px 0 0; 
      .med2Def{width: 90%;}
      .fmbInner{padding-bottom: 10px;
        &::before{background-size:30%;}
      }
      .textHlfTop, .textHlfBtm{width:70%;}
      h4{font-size:18.5px;}
      ul{margin-top: 5px;}
      p, li{font-size:17px;}
      li{margin-bottom: 10px;
        &::before{top:4px;}
      }
    }

    @include bp(br) {padding:0px 0 0; 
      .med2Def{width:750px;}
      .fmbInner::before{width:25%; left:-3%;background-size:100%;}
      .textHlfTop{width:80%;@include center-block(); text-align: center; margin-bottom:15px;}
      .textHlfBtm{width:77%;}
      h4{font-size:18px;}
      ul{margin-top: 0px; padding-left:0;}
      li{margin-bottom: 10px;
        &::before{top:4px;}
      }
    }

    @include bp(md) {
      .med2Def{width:740px;}
      .fmbInner::before{left:-2%;}
      .textHlfTop{width:90%;}
      .textHlfBtm{width:75%; margin-right:2%;}
    }

    @include bp(og) {
      .med2Def{width:90%;}
      .fmbInner{padding-bottom:150px;
        &::before{width:100%; left:0;background-size:125px; background-position: center bottom;}
      }
      .textHlfTop{width:100%; margin-bottom:15px;}
      .textHlfBtm{width:90%; @include center-block();}
      h4{font-size:17px;}
      li{margin-bottom: 10px;
        &::before{top:4px;}
      }
    }
  }
}