@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Contact {@include sec-default();padding:3.5% 0 10%;position:relative;z-index:0; 
  &::before{@include before();height:122%;top:-22%;@include sec-bgimage('./images/holiday.png');background-size:cover;background-position:bottom left;}

  .ContactBody{@include sec-default();}
  .fullbox{@include sec-default(); padding:20px 0 60px;
    background-color:$fa; position: relative; z-index: 0;box-shadow: 0px 8px 30px rgba($color2,.2);
    &::after{@include after();width:1px;background-color:rgba($black, .2);
      // height:50%;bottom:10%;left:39%; 
      height:55%;bottom:10%;left:44%;
    }
  }

  .titlebox{margin-bottom:40px;}
  h1{font-size:2.5rem;text-transform:uppercase;letter-spacing: 5px;
    margin-left:-30px; color:$color2;
  }
  h2{margin:0;text-transform:capitalize;color:$color1;font-weight:bold;}

  .box1, .box2{display: inline-block;}
  .box1{width:40%;float:left;
    margin-left:-2%;
    a,p{font-size:19px;}
    p{margin-bottom: 20px; position: relative; padding-left: 10px;
      &::before{@include before(); width:10px; height:10px; left:-10px; top:6px; @include sec-bgimage('./images/fmbr-disc.png');}
    }
  }
  .box2{width:55%;float:right;
    margin-right:-2%;
    p{font-size:18px;}
  }

  .overImg{display: none; width:200px;position: absolute; right:-5%; top:-80px;}

  @include bp(tb) {
    .med2Def{width:800px;}
    .insideAlt{width:88%;}
    .fullbox::after{left:44.5%;}
  }
  
  @include bp(br) {
    .fullbox{ padding:20px 0 60px;}
    h1{font-size:2.1rem; margin-left:-25px;}
    h2{font-size:1.2rem;}
    .box1{margin-left:0;
      a,p{font-size:18px;}
    }
    .box2 p{font-size:17px;}
  }

  @media (max-width:991px){padding:4% 0 8%;}

  @media screen and (max-width:900px){h1{font-size:2.1rem; margin-left:-15px;}}

  @media (max-width:875px){
    &::before{height:124%;top:-24%;}
    .med2Def{width:550px;}
    .insideAlt{width:480px;}
    
    h1{font-size:1.85rem; margin-left:-25px;}
    h2{font-size:1.1rem;}
    .titlebox{margin-bottom: 20px;}
    .fullbox{padding:15px 0 12px;
      &::after{display: none;}
    }
    .box1,.box2 {@include sec-default();
      p,a{font-size:17px;}
    }
    .box2{padding-right:10px; margin-top:15px;}
  }

  @include bp(og){padding-top:2%;
    .med2Def{width:85%;}
    .insideAlt{width:85%;}
    h1{font-size:1.7rem; margin-left:-15px;}
    .titlebox{margin-bottom: 10px;}
    .box2{padding:15px 0 0;}
  }

  @include bp(oc){padding:0px 0 40px;
    h1{font-size:1.5rem;}
  }
}